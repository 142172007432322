<template>
  <div>
    <div class="filterBox boxFrame mt-6 px-6">
      <div class="d-flex flex-row  header"   @click="showFilters = !showFilters">
        <div class="text-h5 font-weight-medium " >
          <v-icon class="mb-1 mr-1" color="primary">mdi-filter-multiple-outline</v-icon>Filters
        </div>
        <v-icon class="mt-1 ml-auto">{{ showFilters? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
      </div>
      <DefaultAnalysisFilters class="mt-4" v-if="datasets.SV.info !== null && datasets.SNV.info" :selectedDatabases="[datasets.SV.info, datasets.SNV.info]" :showFilters="showFilters" :cohortId="cohortId" @onFilterSet="onFilterSet"></DefaultAnalysisFilters>
    </div>
    
    <div v-if="isExplored"   class="filterBox boxFrame mt-6 ">
      <ViewFilteredVariants ref="ViewFilteredVariants" :filters="filters" :cohortId="cohortId" :projectId="projectId"></ViewFilteredVariants>
    </div>
  </div>
</template>
<script>
import DefaultAnalysisFilters from "@/components/defaultAnalysis/DefaultAnalysisFilters.vue";
// import ShareResult from "@/components/defaultAnalysis/ShareResult.vue";
// import ViewVariantsTable from "@/components/ViewVariantsTable.vue";
import ViewFilteredVariants from "@/components/defaultAnalysis/ViewFilteredVariants.vue"
// import { getDatasetsBySample } from "@/api/dataset.js";
// import { getData } from "@/api/user/dataset";
// import { getDatasetFields } from "@/api/dataset";
// import { getFieldGroups } from "@/api/settings";
// import { getVariantLabels } from "@/api/settings";
// import exportToExcel from "@/mixins/exportToExcel.js"

import { getDatasetsBySample } from "@/api/dataset.js";
import { getDatasetFields } from "@/api/dataset";

export default {
  name: "DefaultAnalysis",
  components: { DefaultAnalysisFilters, ViewFilteredVariants},
  data: () => ({
    cohortId: null,
    projectId: null,
    showFilters: true,
    isExplored: false,
    filter: null,
    datasets: {
      SNV: {
        info: null,
        fields: [],
      },
      SV: {
        info: null,
        fields: [],
      },
    },
  }),
  created() {
    this.cohortId = Number(this.$route.params.cohortid);
    this.projectId = Number(this.$route.params.id);
    const _this = this
    getDatasetsBySample(this.projectId, (res) => {
      Object.keys(this.datasets).forEach((dsType) => {
        _this.datasets[dsType].info = res.find((item) => item.datasetType.name == dsType);
        getDatasetFields(_this.datasets[dsType].info.id, (rs) => {
          _this.datasets[dsType].fields = rs;
        });
      });
    });
  },
  methods: {
    onFilterSet(filters, variantLabels, selectedPropertiesLabels, collabLabelsPerDs) {
      this.isExplored = false;
      this.showFilters  =false
      this.filters = {
        'filters' : filters, 
        'variantLabels' : variantLabels, 
        'selectedPropertiesLabels' : selectedPropertiesLabels, 
        'collabLabelsPerDs' : collabLabelsPerDs
      }
      this.$nextTick(()=>{
        this.isExplored = true
        // this.$refs.ViewFilteredVariants.onFilterSet(filters, variantLabels, selectedPropertiesLabels, collabLabelsPerDs)
      })
    },
  },
};
</script>
<style scoped lang="scss">
.filterBox {
  background: #9aacad13 !important;
}
.header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 6px;
}
.header:hover{
  cursor: pointer;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  background-color: #55555508!important;
}
</style>